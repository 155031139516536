import React from 'react';
import { borderRadius } from '../../../primitives/tokens';
import { colors } from '../../../primitives/colors';
import Link from '../../../primitives/link';
import AspectRatio from '../../../primitives/aspect-ratio';
import MediaItem, { MediaItemAsset } from '../../../primitives/media-item';
import Typography from '../../../primitives/typography';
import StyledLink from '../../../primitives/styled-link';
import DatoAsset from '../../../primitives/asset';

interface ArticleCardProps {
    id: string;
    primaryMedia: MediaItemAsset | null;
    permalink: string | null;
    title: string | null;
    tag: React.ReactNode | null;
    excerpt: string | null;
    thumbnail?: Queries.DatoCmsInternalAssetFragment | null
}

const ArticleCard = ({
    id,
    primaryMedia,
    permalink,
    title,
    tag,
    excerpt,
    thumbnail,
}: ArticleCardProps): JSX.Element => (
    <div
        key={id}
        css={{
            borderRadius: borderRadius.large,
            position: 'relative',
            userSelect: 'none',
            transition: '0.3s',
            border: `1px solid ${colors.grey100}`,
            boxShadow: '0 24px 54px 0 rgba(0, 0, 0, 0.0)',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: colors.shadesWhite,
            '&:hover, &:focus': {
                boxShadow: '0 24px 54px 0 rgba(0, 0, 0, 0.09)',
                border: '1px solid transparent',
            },
        }}
        role="group"
    >
        <Link
            to={permalink || ''}
            css={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0',
                left: '0',
                zIndex: '1',
            }}
        />
        <div>
            {primaryMedia && primaryMedia.length > 0 && (
                <AspectRatio
                    breakpoints={{
                        dt: { width: 16, height: 10 },
                    }}
                    css={{
                        overflow: 'hidden',
                        borderTopLeftRadius: borderRadius.large,
                        borderTopRightRadius: borderRadius.large,
                        transform: 'translateZ(0px)',
                    }}
                >
                    {thumbnail ? (
                        <DatoAsset objectPosition="center 18%" css={{ height: '100%', width: '100%' }} asset={thumbnail} isStatic />
                    ) : (
                        <MediaItem objectPosition="center 18%" css={{ height: '100%', width: '100%' }} item={primaryMedia} isStatic />
                    )}
                </AspectRatio>
            )}
            <div css={{
                paddingTop: '28px',
                paddingLeft: '30px',
                paddingRight: '30px',
                position: 'relative',
            }}
            >
                {tag}
                <Typography
                    css={{
                        paddingTop: '15px',
                    }}
                    fontSize={{
                        dt: 1822,
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    css={{
                        paddingTop: '10px',
                        opacity: 0.7,
                    }}
                    fontSize={{
                        dt: 1322,
                    }}
                >
                    {excerpt}
                </Typography>
            </div>
        </div>
        <div css={{
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingBottom: '34px',
            paddingTop: '28px',
        }}
        >
            <StyledLink
                to=""
                css={{
                    whiteSpace: 'nowrap',
                    transition: '0.4s',
                }}
            >
                Read more
            </StyledLink>
        </div>
    </div>
);

export default ArticleCard;
